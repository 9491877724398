var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4 mt-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(" " + _vm._s(_vm.$t("settings.pdf.tabTitle")) + " ")
            ]),
            _c("hr"),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("settings.pdf.tabDesc")) }
            })
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("settings.pdf.pageSize")))])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-group", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ ariaDescribedbyPageSize }) {
                      return [
                        _c("b-form-radio-group", {
                          attrs: {
                            id: "radio-page-size-options",
                            options: _vm.pageSizeOptions,
                            "aria-describedby": ariaDescribedbyPageSize,
                            name: "radio-page-size-options",
                            stacked: ""
                          },
                          model: {
                            value: _vm.pageSize,
                            callback: function($$v) {
                              _vm.pageSize = $$v
                            },
                            expression: "pageSize"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("settings.pdf.pageSizeDesc"))
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("settings.pdf.pageFormat")))])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-group", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ ariaDescribedbyPageFormat }) {
                      return [
                        _c("b-form-radio-group", {
                          attrs: {
                            id: "radio-page-format-options",
                            options: _vm.pageFormatOptions,
                            "aria-describedby": ariaDescribedbyPageFormat,
                            name: "radio-page-format-options",
                            stacked: ""
                          },
                          model: {
                            value: _vm.pageFormat,
                            callback: function($$v) {
                              _vm.pageFormat = $$v
                            },
                            expression: "pageFormat"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("settings.pdf.pageFormatDesc"))
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isPasswordProtected === true && _vm.isMagazinePublished
        ? _c(
            "b-row",
            { staticClass: "pt-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { variant: "warning", show: "" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("settings.pdf.magazinePasswordProtected")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isMagazinePublished === false
        ? _c(
            "b-row",
            { staticClass: "pt-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { variant: "warning", show: "" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.pdf.magazineNotPublished")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isPasswordProtected && _vm.showPdfTable && _vm.isMagazinePublished
        ? _c(
            "b-row",
            { staticClass: "mb-4 mt-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "text-uppercase mb-0",
                          staticStyle: { "line-height": "36px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.pdf.createdPdf")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "green-button-transparent border-0",
                          on: { click: _vm.createPdf }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "mr-2 top--3",
                            attrs: {
                              icon: "plus-circle-fill",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          }),
                          _vm.pdfItems.length === 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.pdf.createPdfButton")
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.pdf.recreatePdfButton")
                                    ) +
                                    " "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.createPdfError && !_vm.isPasswordProtected && _vm.isMagazinePublished
        ? _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { variant: "danger", show: "" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.pdf.createPdfError")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.showPdfTable &&
          !_vm.isPasswordProtected &&
          _vm.isMagazinePublished
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("b-table", {
                    staticClass: "table-view items-table",
                    attrs: {
                      fields: _vm.pdfTableFields,
                      items: _vm.pdfItems,
                      "show-empty": "",
                      borderless: "",
                      dark: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center my-2" },
                              [
                                _c("b-icon", {
                                  staticClass: "mr-2 top--3",
                                  attrs: {
                                    icon: "exclamation-circle-fill",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("table.noItems")) + " "
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(name)",
                        fn: function(data) {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.buildTheLink(data.item.url)
                                )
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "cell(created)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(data.item.created)) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(status)",
                        fn: function(data) {
                          return [_vm._v(" " + _vm._s(data.item.status) + " ")]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }