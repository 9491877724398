import gql from 'graphql-tag';

export default gql`
query getPdfDataByProjectid($projectId: Int!) {
  projects(where: {project_id: {_eq: $projectId}}) {
    pdf_export_url
    pdf_exported_at
    pdf_export_status
    published
    plugins(where: {handle: {_eq: "plugin_password_protection"}, enabled: {_eq: true}}) {
      enabled
      handle
    }
  }
}
`;
