<template>
  <div>
    <b-row class="mb-4 mt-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.pdf.tabTitle') }}
        </h3>
        <hr>
        <p v-html="$t('settings.pdf.tabDesc')"></p>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.pdf.pageSize') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-group v-slot="{ ariaDescribedbyPageSize }">
          <b-form-radio-group
            id="radio-page-size-options"
            v-model="pageSize"
            :options="pageSizeOptions"
            :aria-describedby="ariaDescribedbyPageSize"
            name="radio-page-size-options"
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <b-form-text
          v-html="$t('settings.pdf.pageSizeDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.pdf.pageFormat') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-group v-slot="{ ariaDescribedbyPageFormat }">
          <b-form-radio-group
            id="radio-page-format-options"
            v-model="pageFormat"
            :options="pageFormatOptions"
            :aria-describedby="ariaDescribedbyPageFormat"
            name="radio-page-format-options"
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <b-form-text
          v-html="$t('settings.pdf.pageFormatDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row v-if="isPasswordProtected === true && isMagazinePublished" class="pt-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-alert
          variant="warning"
          show
          class="mb-0"
        >
          {{ $t('settings.pdf.magazinePasswordProtected') }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="isMagazinePublished === false" class="pt-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-alert
          variant="warning"
          show
          class="mb-0"
        >
          {{ $t('settings.pdf.magazineNotPublished') }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mb-4 mt-5" v-if="!isPasswordProtected && showPdfTable && isMagazinePublished">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="d-flex justify-content-between">
          <h3 class="text-uppercase mb-0" style="line-height: 36px;">
            {{ $t('settings.pdf.createdPdf') }}
          </h3>
          <b-button
            class="green-button-transparent border-0"
            @click="createPdf"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            <span v-if="pdfItems.length === 0">
              {{ $t('settings.pdf.createPdfButton') }}
            </span>
            <span v-else>
              {{ $t('settings.pdf.recreatePdfButton') }}
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="createPdfError && !isPasswordProtected && isMagazinePublished">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-alert
          variant="danger"
          show
          class="mb-0"
        >
          {{ $t('settings.pdf.createPdfError') }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-else-if="showPdfTable && !isPasswordProtected && isMagazinePublished">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-table
          :fields="pdfTableFields"
          :items="pdfItems"
          show-empty
          borderless
          dark
          class="table-view items-table"
        >
          <template #empty>
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noItems') }}
            </div>
          </template>
          <template #cell(name)="data">
            <span v-html="buildTheLink(data.item.url)"></span>
          </template>
          <template #cell(created)="data">
            {{ formatDate(data.item.created) }}
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GetPdfDataByProjectId from '@/graphQlQueries/queries/getPdfDataByProjectId';
import CreatePdfExport from '@/graphQlQueries/mutations/createPdfExport';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'ProjectSettingsPdfExport',
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      checkPDFDataInterval: null,
      getPdfItem: null,
      pageSize: 'a4',
      pageSizeOptions: [
        { text: 'A4', value: 'a4' },
        { text: 'A5', value: 'a5', disabled: true },
      ],
      pageFormat: 'pageBased',
      pageFormatOptions: [
        { text: this.$t('settings.pdf.pageBased'), value: 'pageBased' },
        { text: this.$t('settings.pdf.scrollBased'), value: 'scrollBased', disabled: true },
      ],
      pdfTableFields: [
        {
          key: 'name',
          label: this.$t('settings.pdf.table.name'),
          sortable: false,
          thStyle: { width: 'auto' },
          tdClass: 'name_column',
        },
        {
          key: 'created',
          label: this.$t('settings.pdf.table.created'),
          sortable: false,
          thStyle: { width: '140px' },
          tdClass: 'name_column',
        },
        {
          key: 'status',
          label: this.$t('settings.pdf.table.status'),
          sortable: false,
          thStyle: { width: '100px' },
          tdClass: 'name_column',
        },
      ],
      pdfItems: [],
      showPdfTable: false,
      createPdfError: false,
      isMagazinePublished: null,
      isPasswordProtected: false,
    };
  },
  created() {
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
  },
  apollo: {
    getPdfItem: {
      query: GetPdfDataByProjectId,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      update(data) {
        console.log('data', data);
        this.isMagazinePublished = false;
        if (data.projects.length && data.projects[0].pdf_export_url) {
          this.pdfItems = [{
            url: data.projects[0].pdf_export_url,
            status: data.projects[0].pdf_export_status,
            created: data.projects[0].pdf_exported_at,
          }];
        }
        if (data.projects[0].published) {
          this.isMagazinePublished = data.projects[0].published;
        }
        this.isPasswordProtected = false;
        if (data.projects[0].plugins[0]?.handle === 'plugin_password_protection') {
          this.isPasswordProtected = data.projects[0].plugins[0].enabled;
        }
        this.showPdfTable = true;
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      },
      skip() {
        return !this.projectId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    buildTheLink(pdfUrl) {
      if (!pdfUrl) {
        return 'no pdf exists';
      }
      // get the filename of the URL
      const filename = pdfUrl.substring(pdfUrl.lastIndexOf('/') + 1);
      // make the first letter uppercase
      const name = filename[0].toUpperCase() + filename.slice(1);
      return `<a href="${pdfUrl}" target="_blank">${name}</a>`;
    },
    formatDate(dateString) {
      if (!dateString) {
        return 'no date';
      }
      const date = new Date(dateString.replace(' ', 'T'));
      // const newTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      // return `${newDate} ${newTime}`;
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const fullYear = date.getFullYear();

      const hour = date.getHours();
      const min = date.getMinutes();

      const formatedDate = `${day <= 9 ? `0${day}` : day}.${month <= 9 ? `0${month}` : month}.${fullYear}`;
      const formatedTime = `${hour <= 9 ? `0${hour}` : hour}:${min <= 9 ? `0${min}` : min}`;
      return `${formatedDate} ${formatedTime}`;
    },
    createPdf() {
      this.createPdfError = false;
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.$apollo.mutate({
        mutation: CreatePdfExport,
        variables: {
          projectId: this.projectId,
        },
      }).then((result) => {
        console.log('result', result);
        this.$apollo.queries.getPdfItem.refresh();
        // this.updateThePDFTable();
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }).catch((error) => {
        console.log('PDF Export Error', error);
        this.createPdfError = true;
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      });
      // clearInterval(this.checkPDFDataInterval);
    },
    updateThePDFTable() {
      this.checkPDFDataInterval = setInterval(() => {
        this.$apollo.query({
          query: GetPdfDataByProjectId,
          variables: {
            projectId: this.projectId,
          },
        }).then((result) => {
          // update the table
          console.log('pdf result', result);
        });
        console.log('interval excecuted');
      }, 10000);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .selectize-input {
  background: #5b6064;
  border-color: #303337;
}
.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}
/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      a {
        color: #53DC86;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}
/deep/ .custom-radio {
  input[disabled="disabled"] + label {
    cursor: not-allowed;
    &::before {
      opacity: 0.3;
    }
  }
}
</style>
